<template>
  <master-layout smallTitle="dettaglio" bigTitle="Cliente" :noPadding="true">
    <div>
      <div class="bg-ground2 px-3 pb-4">
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-icon
              @click="closeModal"
              :icon="arrowBackOutline"
              class="arrow-back"
            ></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-center">
            <trCard cardType="gray" class="mb-4">
              <template #main>
                <div class="flex justify-content-between">
                  <div class="flex flex-column align-items-start">
                    <span class="font-bold"
                      ><span class="font-bold"> </span
                      >{{ client.azienda }}</span
                    >
                    <span>{{ client.name }} {{ client.surname }}</span>
                  </div>
                  <div>
                    <span class="font-bold mr-1">ID.</span>
                    <span>{{ client.id }}</span>
                  </div>
                </div>
              </template>
              <template #right-side>
                <div
                  class="flex flex-column align-items-center ion-justify-content-center"
                >
                  <ion-icon style="font-size: 20px" :icon="call"></ion-icon>
                  <span class="mt-1">chiama</span>
                </div>
              </template>
            </trCard>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <div
              @click="toggleShowLeadScoreDetails"
              class="w-full flex align-items-center"
            >
              <span class="text-14 font-bold mr-1">Lead Score </span
              ><ion-icon
                v-if="leadScoreDetailsVisible"
                :icon="chevronDown"
              ></ion-icon>
              <ion-icon v-else :icon="chevronUp"></ion-icon>
            </div>

            <ion-row class="ion-align-items-center mb-2">
              <ion-col class="ion-no-padding">
                <gradient-progress-bar
                  class="height-5 gradient"
                  :value="leadScore"
                ></gradient-progress-bar>
              </ion-col>
              <ion-col size="auto" class="ion-margin-start">
                <span class="font-bold">{{ leadScore * 100 }}%</span>
              </ion-col>
              <ion-col size="auto" class="ion-margin-start">
                <ion-icon
                  v-if="leadScore > 0.59"
                  :icon="thumbsUpSharp"
                  class="thumbs-up"
                ></ion-icon>
                <ion-icon
                  v-else
                  :icon="thumbsDownSharp"
                  class="thumbs-down"
                ></ion-icon>
              </ion-col>
            </ion-row>
            <div v-if="leadScoreDetailsVisible" class="mb-2">
              <div class="w-full pr-0">
                <div
                  v-for="item in leadScoreDetails"
                  :key="item.title"
                  class="pl-4"
                >
                  <ion-row class="ion-align-items-center">
                    <ion-col class="ion-no-padding">
                      <gradient-progress-bar
                        class="height-5 gradient"
                        :value="item.value"
                      ></gradient-progress-bar>
                    </ion-col>
                    <ion-col size="auto" class="ion-margin-start">
                      <span class="font-bold">{{ item.value * 100 }}%</span>
                    </ion-col>
                    <ion-col size="auto" class="ion-margin-start">
                      <ion-icon
                        v-if="item.value > 0.59"
                        :icon="thumbsUpSharp"
                        class="thumbs-up"
                      ></ion-icon>
                      <ion-icon
                        v-else
                        :icon="thumbsDownSharp"
                        class="thumbs-down"
                      ></ion-icon>
                    </ion-col>
                  </ion-row>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="bottoni-attivita my-4">
          <ion-col v-if="settings.show_tutorial"
            ><ion-row class="ion-justify-content-center">
              <ion-icon
                :icon="calendar"
                @click="caricaNuovoEvento"
                style="color: var(--ion-color-primary)"
                class="text-26"
              ></ion-icon> </ion-row
            ><span
              class="flex justify-content-center mt-2 text-align-center text-12"
              >Agenda incontro</span
            ></ion-col
          >
          <ion-col
            ><ion-row class="ion-justify-content-center">
              <ion-icon
                :icon="call"
                @click="caricaNuovaChiamata"
                style="color: var(--ion-color-primary)"
                class="text-26"
              ></ion-icon> </ion-row
            ><span
              class="flex justify-content-center mt-2 text-align-center text-12"
              >Agenda telefonata</span
            ></ion-col
          >
          <ion-col
            ><ion-row class="ion-justify-content-center">
              <ion-icon
                :icon="document"
                @click="caricaNuovoDocumento"
                style="color: var(--ion-color-primary)"
                class="text-26"
              ></ion-icon> </ion-row
            ><span
              class="flex justify-content-center mt-2 text-align-center text-12"
              >Carica documento</span
            ></ion-col
          >
          <ion-col
            ><ion-row class="ion-justify-content-center">
              <ion-icon
                :icon="browsers"
                @click="caricaNuovaNota"
                style="color: var(--ion-color-primary)"
                class="text-26"
              ></ion-icon>
            </ion-row>
            <span
              class="flex justify-content-center mt-2 text-align-center text-12"
              >Nota</span
            >
          </ion-col>
        </ion-row>
        <div
          v-if="
            nuovoDocumentoVisible ||
            nuovaChiamataVisible ||
            nuovoEventoVisible ||
            nuovaNotaVisible
          "
        >
          <ion-row v-if="nuovoDocumentoVisible">
            <ion-col>
              <ion-radio-group
                @ionChange="radioCheck($event)"
                :allowEmptySelection="true"
              >
                <ion-row>
                  <ion-select placeholder="Seleziona il documento">
                    <ion-select-option value="apples"
                      >Locandina marzo 2023</ion-select-option
                    >
                    <ion-select-option value="oranges"
                      >Listino prezzi rivenditore primavera
                      2023</ion-select-option
                    >
                    <ion-select-option value="bananas"
                      >Placeholder logo yoUBe</ion-select-option
                    >
                  </ion-select>
                </ion-row>
              </ion-radio-group>
            </ion-col>
          </ion-row>
          <ion-row v-if="showTaskDetails">
            <ion-col>
              <ion-row>
                <ion-col>
                  <ion-row>
                    <ion-col>
                      <pdf src="sample-pdf.pdf" :page="1" />
                      <!-- <iframe
                    src="../../../public/docs/esempio.pdf"
                    frameBorder="0"
                    scrolling="auto"
                    width="100%"
                    height="100%"
                  ></iframe> -->
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-col>
                      <ion-textarea
                        :autoGrow="true"
                        :qutocapitalize="true"
                        :clearonedit="true"
                        v-model="textarea"
                        placeholder="Scrivi messaggio..."
                      ></ion-textarea>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-button
                    @click="
                      () => {
                        showTaskDetails = false
                        textarea = ''
                        nuovoDocumentoVisible = false
                      }
                    "
                    expand="block"
                  >
                    Invia documento
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row v-if="nuovaChiamataVisible || nuovoEventoVisible">
            <ion-col>
              <ion-datetime
                v-model="nuovoEventoData"
                hour-cycle="h24"
              ></ion-datetime>
            </ion-col>
          </ion-row>
          <ion-row v-if="nuovaNotaVisible">
            <ion-col>
              <ion-row>
                <ion-col>
                  <ion-textarea
                    :autoGrow="true"
                    :qutocapitalize="true"
                    :clearonedit="true"
                    v-model="textarea"
                    placeholder="Scrivi una nuova nota..."
                  ></ion-textarea>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-button @click="nuovaNotaVisible = false" expand="block">
                    Salva nota
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </div>
      </div>

      <div class="px-3 mt-4">
        <ion-row class="ion-margin-top ion-padding-top">
          <ion-col>
            <span class="text-14 font-bold mr-1">Attività svolte </span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-row v-for="task in taskList" :key="task.id">
              <ion-col>
                <task-component
                  :id="task.id"
                  :title="task.title"
                  :description="task.description"
                  :date="task.date"
                  :isDone="task.isDone"
                  :comment="task.comment"
                ></task-component>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </master-layout>
</template>

<script>
import {
  IonButtons,
  IonDatetime,
  IonModal,
  IonPopover,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonCheckbox,
  IonProgressBar,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonCard,
  modalController,
} from "@ionic/vue"
import {
  arrowBackOutline,
  thumbsUpSharp,
  thumbsDownSharp,
  document,
  calendar,
  call,
  browsers,
  chevronDown,
  chevronUp,
} from "ionicons/icons"
import { defineComponent } from "vue"
import GradientProgressBar from "../../components/GradientProgressBar.vue"
import ModalLeadScore from "../Modals/ModalLeadScore.vue"
import TaskComponent from "../../components/TaskComponent.vue"
import pdf from "pdfvuer"
import trCard from "@/components/Nuovi/trCard.vue"

export default defineComponent({
  components: {
    pdf,
    IonButtons,
    IonDatetime,
    IonModal,
    IonPopover,
    IonListHeader,
    IonRadio,
    IonRadioGroup,
    IonCheckbox,
    IonProgressBar,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonList,
    IonItem,
    IonTextarea,
    IonCard,
    GradientProgressBar,
    TaskComponent,
    trCard,
  },
  name: "ModalActualClient",
  props: {
    client: Object,
  },

  data() {
    return {
      leadScoreDetailsVisible: false,
      chevronUp,
      chevronDown,
      document,
      calendar,
      call,
      browsers,
      documentUrl: "../../../public/sample-pdf.pdf",
      thumbsUpSharp,
      thumbsDownSharp,
      arrowBackOutline,
      aziendaSelezionata: "",
      ragioneSociale: "",
      pIva: null,
      nota: "",
      leadScore: 0.78,
      leadScoreDetails: [
        { title: "Locandina marzo 2023", value: 0.4 },
        { title: "Listino prezzi rivenditore primavera 2023", value: 0.8 },
        { title: "Placeholder logo YoUBe", value: 0.4 },
      ],
      tasksCategories: [{ val: "Invio" }],
      showTaskDetails: false,
      textarea: "",
      datetime: null,
      taskList: [
        {
          id: 1,
          title: "Invio",
          description: "DOCUMENTO ESEMPIO",
          date: "30/01/2022",
          isDone: true,
          comment: "",
        },
        {
          id: 2,
          title: "Invio",
          description: "DOCUMENTO ESEMPIO",
          date: "26/01/2022",
          isDone: true,
          comment: "",
        },
        {
          id: 3,
          title: "Invio",
          description: "DOCUMENTO ESEMPIO",
          date: "13/02/2022",
          isDone: true,
          comment:
            "COMMENTO Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis lobortis elementum tincidunt.",
        },
      ],
      nuovoEventoVisible: false,
      nuovaChiamataVisible: false,
      nuovoDocumentoVisible: false,
      nuovaNotaVisible: false,
      nuovoEventoData: null,
    }
  },

  methods: {
    toggleShowLeadScoreDetails() {
      this.leadScoreDetailsVisible = !this.leadScoreDetailsVisible
    },
    caricaNuovoEvento() {
      this.nuovoEventoVisible = true
      this.nuovaChiamataVisible = false
      this.nuovoDocumentoVisible = false
      this.nuovaNotaVisible = false
    },
    caricaNuovaChiamata() {
      this.nuovoEventoVisible = false
      this.nuovaChiamataVisible = true
      this.nuovoDocumentoVisible = false
      this.nuovaNotaVisible = false
    },
    caricaNuovoDocumento() {
      this.nuovoEventoVisible = false
      this.nuovaChiamataVisible = false
      this.nuovoDocumentoVisible = true
      this.nuovaNotaVisible = false
    },
    caricaNuovaNota() {
      this.nuovoEventoVisible = false
      this.nuovaChiamataVisible = false
      this.nuovoDocumentoVisible = false
      this.nuovaNotaVisible = true
    },
    closeModal() {
      return modalController.dismiss()
    },
    chiama(item) {
      alert("Sto chiamando " + item.name)
    },
    radioCheck(e) {
      e.detail.value
        ? (this.showTaskDetails = true)
        : (this.showTaskDetails = false)
    },
  },
})
</script>

<style scoped lang="scss">
/* * {
  border: 1px solid greenyellow;
} */

ion-grid {
  width: 90%;
  margin: auto;
}

ion-label {
  font-size: 0.8rem;
}

ion-radio::part(container) {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 2px solid grey;
}

ion-radio::part(mark) {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  --color-checked: var(--ion-color-primary);
}

ion-textarea {
  border: 1px solid grey;
  border-radius: var(--ion-border-radius);
  --placeholder-opacity: 0.2;
  padding: 0.5rem 1rem;
}

.checkbox {
  height: 40px;
}

.details {
  font-size: 0.8rem;
  text-decoration: underline;
}

.hr {
  width: 100%;
  height: 1px;
  margin-bottom: 2px;
  background: var(--ion-color-primary);
}

.no-padd-no-mar {
  width: 100%;
  padding: 0;
  margin: 0;
}

.place-center {
  display: grid;
  place-items: center;
}

.arrow-back {
  padding-top: 0.5rem;
  font-size: 1.5rem;
}

.thumbs-up {
  color: green;
}
.thumbs-down {
  color: red;
}

.bottoni-attivita {
  ion-icon {
    border: 1px solid var(--ion-color-primary);
    border-radius: 50%;
    background: var(--ion-color-primary);
    color: #fff !important;
    padding: 0.6rem;
  }
}

.bg-ground2 {
  background: var(--client-ground2-color);
}

.bg-ground1 {
  background: var(--client-ground1-color);
}
</style>
